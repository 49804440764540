
.main-container{
  width: 100%;
  height:100vh;
  background: #fafafa;

}

.reset-password-container{
    /* background: rgb(39, 41, 61); */
    width: 500px;
    height: fit-content;
    min-width: fit-content;
    display: inline-block;
    position: absolute;
    /* border: 1px solid white; */
    /* border-radius: 4px; */
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    min-height: 400px;
    transform: translate(-50%, -50%);
    

    
}
.reset-form-container {
    padding: 30px;
    height: fit-content;

  }
  
  .reset-password-heading {
    margin-top: 20px;
    color:#000000!important

  }
  .reset-password-para {
    margin-top: 10px;
    font-size: 12px;
    /* color:#FFFFFF!important */
  }
  
  .reset-password-heading,
  .reset-password-para {
    color: #56584d;
    text-align: center;
  }
  
  .reset-password-input-container {
    margin-top: 25px;
  }
  /* .email-input-icons{
    position: absolute;
    left: 0;
    bottom: 50%;
    transform: translateY(50%);
    font-size: 25px;
    color: gray;
    width: 34px;
    border-right: 1px solid gray;
  } */
  .reset-sub-btn {
    width: 100%;
    border-radius: 5px;
    /* background: #dd4b39; */
    color: #ffffff;
    position: relative;
    height: 40px;
    font-size: 0.875rem;
    cursor: pointer;
    margin-top: 16px;
    border: none;
  }
  /* .reset-sub-btn:hover {
    opacity: 0.8;
    transition: 0.5s ease-out;
  } */
  .click-login-container {
    position: relative;
  }
  .click-login-para {
    position: absolute;
    right: 0;
    font-size: 14px;
    font-weight: 500;
    color: #56584d;
    margin-top: 5px;
  }
  .click-login-btn {
    background: none;
    font-weight: bold;
    color: #56584d;
    font-size: 15px;
  }
  .sign-in-btn{
    /* background: #3f51b5; */
    color:#FFFFFF;
    width: 100%;
    border-radius: 5px;
    position: relative;
    height: 40px;
    font-size: 0.875rem;
    cursor: pointer;
    margin-top: 16px;
    border: none;
  }
  .page-not-found{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:"#000000"
  }