.forgot-password-container{
    /* width:25%; */
    margin: 10% auto;
    /* background: white!important; */
    width: 100%;
    height: 100%;
    
}
.emailComp-container {
    padding: 20px 0 30px 0;
    height: fit-content;
  }
  
  .forgot-password-heading {
    margin-top: 20px;
    color:#000000!important

  }
  .forgot-password-para {
    margin-top: 10px;
    font-size: 12px;
    color:#000000!important
  }
  
  .forgot-password-heading,
  .forgot-password-para {
    /* color: #56584d; */
    text-align: center;
  }
  
  .forgot-password-input-container {
    margin-top: 25px;
  }
  .email-input-icons{
    position: absolute;
    left: 0;
    bottom: 50%;
    transform: translateY(50%);
    font-size: 25px;
    color: gray;
    width: 34px;
    border-right: 1px solid gray;
  }
  .email-sub-btn {
    width: 100%;
    border-radius: 5px;
    /* background: #dd4b39; */
    
    color: #ffffff;
    position: relative;
    height: 40px;
    font-size: 0.875rem;
    cursor: pointer;
    margin-top: 16px;
    border: none;
  }
  /* .email-sub-btn:hover {
    opacity: 0.8;
    transition: 0.5s ease-out;
  } */
  .click-login-container {
    position: relative;
  }
  .click-login-para {
    position: absolute;
    right: 0;
    font-size: 14px;
    font-weight: 500;
    color: #56584d;
    margin-top: 5px;
  }
  .click-login-btn {
    background: none;
    font-weight: bold;
    color: #56584d;
    font-size: 15px;
  }
  .sign-in-btn{
    /* background: #3f51b5; */
    color:#FFFFFF;
    width: 100%;
    border-radius: 5px;
    position: relative;
    height: 40px;
    font-size: 0.875rem;
    cursor: pointer;
    margin-top: 16px;
    border: none;
  }
  