
/*
 =========================================================
 * Black Dashboard React v1.1.0 based on Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 // Core

@import "black-dashboard-react/custom/functions";
@import "black-dashboard-react/bootstrap/functions";

@import "black-dashboard-react/custom/variables";
@import "black-dashboard-react/bootstrap/variables";

@import "black-dashboard-react/custom/mixins";
@import "black-dashboard-react/bootstrap/mixins";

 // Bootstrap components

@import "black-dashboard-react/bootstrap/root";
@import "black-dashboard-react/bootstrap/reboot";
@import "black-dashboard-react/bootstrap/type";
@import "black-dashboard-react/bootstrap/images";
@import "black-dashboard-react/bootstrap/code";
@import "black-dashboard-react/bootstrap/grid";
@import "black-dashboard-react/bootstrap/tables";
@import "black-dashboard-react/bootstrap/forms";
@import "black-dashboard-react/bootstrap/buttons";
@import "black-dashboard-react/bootstrap/transitions";
@import "black-dashboard-react/bootstrap/dropdown";
@import "black-dashboard-react/bootstrap/button-group";
@import "black-dashboard-react/bootstrap/input-group";
@import "black-dashboard-react/bootstrap/custom-forms";
@import "black-dashboard-react/bootstrap/nav";
@import "black-dashboard-react/bootstrap/navbar";
@import "black-dashboard-react/bootstrap/card";
@import "black-dashboard-react/bootstrap/breadcrumb";
@import "black-dashboard-react/bootstrap/pagination";
@import "black-dashboard-react/bootstrap/badge";
@import "black-dashboard-react/bootstrap/jumbotron";
@import "black-dashboard-react/bootstrap/alert";
@import "black-dashboard-react/bootstrap/progress";
@import "black-dashboard-react/bootstrap/media";
@import "black-dashboard-react/bootstrap/list-group";
@import "black-dashboard-react/bootstrap/close";
@import "black-dashboard-react/bootstrap/modal";
@import "black-dashboard-react/bootstrap/tooltip";
@import "black-dashboard-react/bootstrap/popover";
@import "black-dashboard-react/bootstrap/carousel";
@import "black-dashboard-react/bootstrap/utilities";
@import "black-dashboard-react/bootstrap/print";

 // Custom components

 @import "black-dashboard-react/custom/alerts.scss";
 @import "black-dashboard-react/custom/badges.scss";
 @import "black-dashboard-react/custom/buttons.scss";
 @import "black-dashboard-react/custom/dropdown.scss";
 @import "black-dashboard-react/custom/footer.scss";
 @import "black-dashboard-react/custom/forms.scss";
 @import "black-dashboard-react/custom/images.scss";
 @import "black-dashboard-react/custom/modal.scss";
 @import "black-dashboard-react/custom/navbar.scss";
 @import "black-dashboard-react/custom/type.scss";
 @import "black-dashboard-react/custom/tables";
 @import "black-dashboard-react/custom/checkboxes-radio";
 @import "black-dashboard-react/custom/fixed-plugin";
 @import "black-dashboard-react/custom/sidebar-and-main-panel.scss";
 @import "black-dashboard-react/custom/misc.scss";
 @import "black-dashboard-react/custom/rtl.scss";
 @import "black-dashboard-react/custom/input-group.scss";


 // Vendor / Plugins

@import "black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss";

// light mode

@import "black-dashboard-react/custom/white-content.scss";

// Cards

@import 'black-dashboard-react/custom/card';
@import "black-dashboard-react/custom/cards/card-chart";
@import "black-dashboard-react/custom/cards/card-map";
@import "black-dashboard-react/custom/cards/card-user";
@import "black-dashboard-react/custom/cards/card-task";
@import "black-dashboard-react/custom/cards/card-plain";

// React differences
@import "black-dashboard-react/react-differences/react-differences";


.css-1r4vtzz {
    background: #1e1e2e !important;
    width: 100% !important;
  }

.table-responsive::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

// =====================================================================*/
.chatBotWidget_Container {
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  max-width: 370px;
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 90vw;
  z-index: 2;
}

.chatBotWidget_Conversation {
  height: 76vh;
  border-radius: 10px;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.chatBotWidget-header {
  background-color: #2196f3;
  border-radius: 10px 10px 0 0;
  color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding: 25px 0 25px;
}
.chatBotWidget-msg_Container {
  background-color: #fff;
  height: 50vh;
  max-height: 410px;
  overflow-y: scroll;
  padding-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  -webkit-overflow-scrolling: touch;
}
.chatBotWidget-message {
  margin: 10px;
  display: -ms-flexbox;
  display: flex;
  word-wrap: break-word;
}

.chatBotWidget-clientMsg {
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: auto;
}

.chatBotWidget-client_txt {
  background-color: #a3eaf7;
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}

.chatBotWidget-client_txt > p {
  margin: 0;
}

.chatBotWidget-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}
.chatBotWidget-response {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
}
.chatBotWidget {
  display: flex;
  width: 60px;
  justify-content: center;
  box-shadow: 0 2px 10px 1px #b5b5b5;
  -webkit-box-shadow: 0 2px 10px 1px #b5b5b5;
  -moz-box-shadow: 0 2px 10px 1px #b5b5b5;
  height: 60px;
  border-radius: 50px;
  align-items: center;
  // right: 20px;
  // position: fixed;
  // bottom: 20px;
  align-self: flex-end;
  margin-top: 15px;
  cursor: pointer;
  background-color: #1d8cf8;
}

.chatBotWidget_icon {
  background-color: #1d8cf8;
  color: #fff;
  border: 0px;
  font-weight: bold;
}
.chatBotWidget-sender {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  background-color: #f4f7f9;
  height: 45px;
  padding: 5px;
  border-radius: 0 0 10px 10px;
}
.chatBotWidget-new-message {
  width: 100%;
  border: 0;
  background-color: #f4f7f9;
  height: 30px;
  padding-left: 15px;
  resize: none;
}

.loader-container_CB {
  border-radius: 10px;
  padding: 15px;
  max-width: 215px;
  text-align: left;
}

.loader-dots_CB {
  background-color: gray;
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  margin-right: 2px;
  animation: a 0.5s ease infinite alternate;
}

.loader-dots_CB:first-child {
  animation-delay: 0.2s;
}

.loader-dots_CB:nth-child(2) {
  animation-delay: 0.3s;
}

.loader-dots_CB:nth-child(3) {
  animation-delay: 0.4s;
}

// Scrollbar custom
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #13849657;
}

*::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  width: 6px;
}
