select.form-control option {
   color: #888;
}

.dateclass {
   width: 100%;
 }
 
 .dateclass.placeholderclass::before {
   width: 100%;
   content: attr(placeholder);
 }
 
 .dateclass.placeholderclass:hover::before {
   width: 0%;
   content: "";
 }